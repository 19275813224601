import React, { useEffect } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { BASE_URL, access_token, auth_token } from "../services/config";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const Othercontactus = () => {
  const successgif = "/images/success.gif";
  const contactimg = "/images/contactus.png";

  const regionsData = useSelector((state) => state.region.regionsData);

  const [contactmodal, setContactmodal] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleFeebackModal = () => {
    setContactmodal(true)
  }

  const handleFeebackModalClose = () => {
    setContactmodal(false)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmitLoad, setFormSubmitLoad] = useState(false);
  const [fileUploadError, setFileUploadError] = useState("");
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    mobile_number: "",
    issue_type: "",
    file: "",
    message: "",
  });
  const [error, setError] = useState("");
  const [usererror, setUserError] = useState("");
  const [useremailerror, setUserEmail] = useState("");
  const [usermblerror, setUserMblnum] = useState("");
  const [userissuetype, setUserissue_type] = useState("");

  const handleChange = (event) => {
    console.log(event, "event");
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMobileNumberInput = (event) => {
    const { name, value } = event.target;

    if (name === "mobile_number") {
      const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
      const limitedValue = numericValue.slice(0, 15); // Take only the first 15 digits
      console.log(limitedValue, "limitedValue");

      setFormData({
        ...formData,
        [name]: limitedValue,
      });
    }
  };

  console.log(error, "------->error");

  const onFileChange = (event) => {
    let supportedFiles = ["image/png", "image/jpeg", "image/gif"];
    let fileData = event.target.files[0];
    console.log(fileData, "event from On Change");
    if (event.target.files && event.target.files.length > 0) {
      if (!supportedFiles.includes(fileData.type)) {
        setFileUploadError("File Not Supported");
        setFormData({ ...formData, file: "" });
        console.log(fileData.type, "fileData-Type");
        return;
      } else if (fileData.size > 1250000) {
        console.log("Size Larger than 1 mb");
        setFileUploadError("Size Larger than 1 MB");
        setFormData({ ...formData, file: "" });
        return;
      } else {
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
          let file = event.target.files[0];
          reader.readAsDataURL(file);
          reader.onload = () => {
            const blobData =
              "data:" + file.type + ";base64," + reader.result.split(",")[1];
            console.log(blobData, "blobData");
            setFormData({ ...formData, file: blobData });
            setFileUploadError("");
          };
        }
      }
    } else {
      setFileUploadError("");
    }
  };

  const updatedformData = {
    ...formData,
    access_token: access_token,
    auth_token: auth_token,
    region: regionsData?.country_code2,
  };

  console.log(updatedformData, "updatedformData");

  const checkFormValidation = (data) => {
    if (!data.user_name && data.user_name === "") {
   //   setError("*Please Enter Name");
      setUserError("*Please Enter Name");
      return false;
    } else if (!data.user_email && data.user_email === "") {
    //  setError("*Please Enter Email ID");
      setUserEmail("*Please Enter Email ID");
      return false;
    } else if (
      data.user_email !== "" &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(data.user_email)
    ) {
      //setError("Enter a Valid Email");
      setUserEmail("*Enter a Valid Email");

      return false;
    } else if (!data.mobile_number && data.mobile_number === "") {
    //  setError("*Please Enter Mobile Number");
      setUserMblnum("*Please Enter Mobile Number");

      return false;
    } else if (data.mobile_number !== "" && data.mobile_number.length < 10) {
     // setError("Enter a Valid Mobile Number");
      setUserMblnum("Enter a Valid Mobile Number");
      return false;
    } else if (!data.issue_type && data.issue_type === "") {
     // setError("*Please Select the Issue");
      setUserissue_type("*Please Select the Issue");

      return false;
    } else if (!data.file && data.file === "") {
      // setError("*Please Select the Issue");
      setFileUploadError("*Please Select Image");
 
       return false;
     } else if (!data.message && data.message === "") {
      setError("*Please Enter Message");
      return false;
    } else {
      setUserError("");
      setUserEmail("");
      setUserMblnum("");
      setUserissue_type("");
      setError("");
      return true;
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    try {
      if (checkFormValidation(formData)) {
        // setFormSubmitLoad(true);
        const contactDetailsUpdateUrl = `${BASE_URL}users/contact`;
        console.log(updatedformData, "updatedformData");
        await axios.post(
          contactDetailsUpdateUrl,
          JSON.stringify(updatedformData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setFormData({
          user_name: "",
          user_email: "",
          mobile_number: "",
          issue_type: "",
          file: "",
          message: "",
        })
        console.log("Contact Us Form Submitted Successfully!");
        // setFormSubmitted(true);
        setContactmodal(true)
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Some Error Occured, Please Try Again.");
      // setFormSubmitLoad(false);
      setContactmodal(false)
    }finally {
      setLoading(false); 
    }
  };

  return (
    <>
      {/* <Header /> */}
      <div className="main ">
        <Container>
          <h4>Contact Us </h4>
          <div className="about-main">
            <Row>
              <Col lg={6} style={{ display: "flex", alignItems: "center" }}>
                <div className="etv-data">
                  <img src={contactimg} alt="contactimage" />
                  <h6 className="etv-heding-style">ETV Win</h6>
                  <p className="p-heding-style">
                    ETV Building, 2nd Floor Ramoji Film City Hyderabad - 501512
                    - Telangana - India
                  </p>
                  <div className="phone-number-style">
                    <p>
                      Phone:
                      <Link to="tel:+91 8142212345"> +91 8142212345,</Link>
                      <Link to="tel:+91 8008020555">+91 8008020555</Link>
                    </p>
                  </div>
                  <div className="emailadres-style">
                    <h6>Mon-Sun 7 AM - 10 PM (IST)</h6>
                    E mail : <Link to="mailto:contact@etvwin.com">
                       contact@etvwin.com
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={6} style={{ display: "flex", alignItems: "center" }}>

              <div className="form-styling-new content-profiles border-styw">
                    <form onSubmit={(event) => handleFormSubmit(event, setLoading)}>
                    {error && <p style={{ color: "yellow" }}>{error}</p>}
                    
                      <div className="form-group lable-space">
                        <label for="checkbox1">Name</label>
                        <input
                          type="text"
                          name="user_name"
                          onChange={handleChange}
                        />
                       {usererror && <p style={{ color: "yellow" }}>{usererror}</p>}

                      </div>

                      <div className="form-group lable-space">
                        <label for="checkbox1">Email</label>
                        <input
                          type="email"
                          name="user_email"
                          autocomplete="off"
                          onChange={handleChange}
                        />
                        
                        {useremailerror && <p style={{ color: "yellow" }}>{useremailerror}</p>}

                      </div>
                      <div className="form-group lable-space">
                        <label for="checkbox1">Phone Number</label>
                        <input
                          type="tel"
                          name="mobile_number"
                          autocomplete="off"
                          maxLength={15}
                          value={formData.mobile_number}
                          pattern="[0-9]*"
                          onChange={handleMobileNumberInput}
                        />
                      </div>
                      {usermblerror && <p style={{ color: "yellow" }}>{usermblerror}</p>}
                      
                      
                      <div className="form-group lable-space">
                        <label for="checkbox1">Select Your issue</label>
                        <select
                          name="issue_type"
                          tabindex="-1"
                          placeholder="Select your issue"
                          onChange={handleChange}
                        >
                          <option value=""></option>
                          <option value="Payment Issue">Payment Issue</option>
                          <option value="Subscription Issue">
                            Subscription Issue
                          </option>
                          <option value="Other Issue">Other Issue</option>
                        </select>
                        {userissuetype && <p style={{ color: "yellow" }}>{userissuetype}</p>}

                      </div>
                      <div className="form-group lable-space uploadFile">
                        <label for="checkbox1">Upload Image</label>
                        
                        <input
                          type="file"
                          name="file"
                          autocomplete="off"
                          onChange={onFileChange}
                          style={{ padding: "9px 0 0 16px !important" }}
                        />
                        <small> Accepted extension .png, .jpg, .jpeg, .gif and upto 1 mb</small>
                        {fileUploadError && (
                          <p style={{ color: "yellow" }}>*{fileUploadError}</p>
                        )}
                      </div>

                      
                      <div className="form-grou">
                          <label for="checkbox1">Message</label>
                          <textarea
                            name="message"
                            onChange={handleChange}
                          ></textarea>
                        {error && <p style={{ color: "yellow" }}>{error}</p>}

                      </div>
                      

                      <button
                        className="next-button-color"
                        disabled=""
                        onClick={handleFormSubmit}
                      >
                        {loading ? <Spinner /> : 'Submit'}
                      </button>

                     
                    </form>

                  </div>
              </Col>
            </Row>
          </div>
        </Container>


       
      </div>
      {contactmodal  && (
        <Modal
          show={contactmodal }
          onHide={handleFeebackModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
          <div className="card-deck">
              <Row className="justify-content-center">
                <Col lg={12} md={12} sm={12}>
                  <div className="successPopup">
                    <img src={successgif} alt="" />
                    <h6>Thank You!</h6>
                    <p>We have received your message and Will get back to you shortly.</p>
               
                  </div>
                  
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
     

    </>
  );
};

export default Othercontactus;
