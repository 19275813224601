import { Col, Container, Form, Row } from "react-bootstrap";
import crypto from "crypto-js";
import Footer from "../Componets/Footer/Footer";
import Header from "../Componets/Header/Header";
import { setModalOpen } from "../Redux/Slice/loginModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { postData } from "../services/helper";
import { useEffect, useState } from "react";
import {
  BASE_URL,
  access_token,
  auth_token,
  getSecretKey,
} from "../services/config";
import { setRedeemData } from "../Redux/Slice/redeemSlice";

const RedeemScreen = () => {
  const GiftBoxIcon = "/images/gift-box.png";

  const dispatch = useDispatch();
  const isUserLoggedIn = localStorage.getItem("login_status");
  const sessionId = localStorage.getItem("session_id");
  const regionsData = useSelector((state) => state.region.regionsData);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    coupon_code: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [successMessage, setSuccessMessage] = useState('')
  const [couponresdata, setCouponresdata] = useState({})
  const [errorMessage, setErrorMesage] = useState('')

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const applyCouponCodeUrl = `${BASE_URL}/users/${sessionId}/apply_redeem_coupon`;
  const redeemCouponCode = async (e) => {
    e.preventDefault()
    const md5val = getSecretKey + sessionId + regionsData.country_code2;
    const md5Hash = crypto.MD5(md5val).toString();
    const couponDetails = {
        auth_token,
        access_token,
        us: md5Hash,
        region: regionsData?.country_code2,
        coupon_code: formData?.coupon_code,
      }
    setLoading(true);
    try {
      const data = await postData(applyCouponCodeUrl, couponDetails, setSuccessMessage, setErrorMesage);
      setCouponresdata(data.data)
      dispatch(setRedeemData({ redeemData: data.data }));
      console.log("posted data:", data);
      setFormData({
        coupon_code: ""
      })
      setLoading(false);
    } catch (error) {
      console.error("Error fetching home page details:", error);
    }
  };



  return (
    <>
      <div className="main-page">
        <div className="main background-layer">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={8}>
                <div className="redeemScreen form-style">
                  <div className="stepper-wrapper">
                    <div
                      className={`stepper-item ${
                        isUserLoggedIn == "true" ? "completed" : ""
                      }`}
                    >
                      <div className={`step-counter ${
                        isUserLoggedIn === "false" ? "completed" : ""
                      }`}>1</div>
                      <div className="step-name">Sign In</div>
                    </div>
                    {/* <div className="stepper-item"> */}
                    <div className={`stepper-item ${
                        couponresdata?.order_id ? "completed" : ""
                      }`}>
                      <div className={`step-counter ${
                        isUserLoggedIn === "true" ? "completed" : ""
                      }`}>2</div>
                      <div className="step-name">Apply Promo Code</div>
                    </div>
                    <div className={`stepper-item ${
                        couponresdata?.order_id ? "completed" : ""
                      }`}>
                      <div className={`step-counter ${
                        couponresdata?.order_id ? "completed" : ""
                      }`}>3</div>
                      <div className="step-name">Confirmation</div>
                    </div>
                  </div>
                  <p className="text-center text-danger errorMessage fs-5">
                        {errorMessage ? `${errorMessage}` : ""}{" "}
                      </p>
                  {isUserLoggedIn == "true" ? (
                    <div className={`redeemContent ${couponresdata?.order_id ? "d-none" : ""}`} id="PromoCode">
                    {/* <div className="redeemContent" id="PromoCode"> */}
                      <h3>Redeem your Promo Code</h3>
                      <Row className="justify-content-center">
                        <Col lg={6}>
                          <Form>
                            <input
                              type="text"
                              name="coupon_code"
                              value={formData.coupon_code}
                              onChange={handleChange}
                              placeholder="Enter Promo Code"
                              className="mb-4"
                            />
                            <button onClick={redeemCouponCode}  disabled={!formData.coupon_code.trim()} type="button" className="reeemBtn">
                              apply
                            </button>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="redeemContent" id="SignIn">
                      <h3>Redeem your Promo Code</h3>
                      <Link
                        to=""
                        className="reeemBtn"
                        onClick={() => dispatch(setModalOpen())}
                      >
                        Login
                      </Link>
                    </div>
                  )}

                  {couponresdata?.order_id && <div className="redeemContent" id="Confirmation">
                    <img src={GiftBoxIcon} alt="" />
                    <p className="mb-4">
                     {couponresdata?.message}
                    </p>
                    <Link to="/home" className="reeemBtn">
                      Proceed
                    </Link>
                  </div>}

                  
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default RedeemScreen;
