import React, { useState, useRef, useEffect } from "react";
import crypto from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
// import "./video.css"; 
import "video-react/dist/video-react.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import {
  BASE_URL,
  GET_ALL_DETAILS,
  access_token,
  auth_token,
  getSecretKey,
} from "../../services/config";
import axios from "axios";
import CustomVideoPlayer from "../../Componets/CustomVideoPlayer";
import { setModalOpen } from "../../Redux/Slice/loginModalSlice";
import Likefeature from "../videodetails/likefeature";
import ShareFeature from "../videodetails/share";
import { SkeletonVideoThumbnail } from "../skeletonLoad/skeletonthumbnail";
import { setShowLogoutAllModal } from "../../Redux/Slice/logoutAllModalSlice";
import { setGetallDetails } from "../../Redux/Slice/getalldetailsSlice";
import { Helmet } from "react-helmet";
import GPTAd from "../videodetails/gptadd";
import { setSubscribeSource } from "../../Redux/Slice/subscriptionsource";

const LiveVideoPlayer = ({ catalogid, contentid }) => {
  const addBanner = "/images/add-01.png";
  // console.log("catalogid", catalogid)
  // console.log("contentid", contentid)
  const { isLoggedIn } = useSelector((state) => state.auth);
  const regionsData = useSelector((state) => state.region.regionsData);
  const { sessionId } = useSelector((state) => state.auth);
   let sessionID = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;
    const navigate = useNavigate()
    
  const effectRan = useRef(false);
  const { videodetailsitems } = useSelector((state) => state.video_details);

  const selectedCatalogID = catalogid || videodetailsitems?.catalog_id || videodetailsitems?.last_episode?.catalog_id
  const selectedContentID = contentid || videodetailsitems?.content_id || videodetailsitems?.last_episode?.content_id

  console.log("videodetailsitems", videodetailsitems);

  let detailsData = videodetailsitems;
  const playerRef = useRef(null);
  const { category, main, submain, seo_url, seo_url_friendly } = useParams();
  const [isVideoPlay, setIsVideoPlay] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [detailssubData, setDetailsData] = useState({});
  const [getPreviewWithoutLogin, setGetPreviewWithoutLogin] = useState({});
  const [showPreviewVideo, setShowPreviewVideo] = useState(true);
  const [datacontentId, setdatacontentId] = useState("");
  const [redirectSeourl, setredirectSeourl] = useState("");
  const [layer, setLayer] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      setShowPreviewVideo(true);
    }
  }, [isLoggedIn]);


  const handleShowLayer = () => {
    setLayer(true);
    setTimeout(() => {
      handlePlay();
    }, 500);
  };
  const handlePlay = () => {
    if (playerRef.current) {
      playerRef.current.play();
      setIsPlaying(true);
      setIsVideoPlay(true);
    }
  };


  // useEffect(() => {
  //   if (effectRan.current === false) {
  //     fetchDetailsPage();
  //     return () => {
  //       effectRan.current = true;
  //     };
  //   }
  // }, []);

  const fetchDetailsPage = async (url) => {
    try {
      const detailsUrl = `${BASE_URL}catalogs/${category}/items/${seo_url}?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}`;
      const response = await axios.get(detailsUrl);
      if (response.status === 200) {
        var respdata = response.data.data;
        const lastEpisode = respdata?.last_episode;
        if (category !== "live") {
          if (respdata.last_episode.seo_url !== "") {
            setredirectSeourl(respdata.last_episode.seo_url);
          }
        }

        if (lastEpisode) {
          setdatacontentId(
            lastEpisode.content_id || contentid
          );
        } else {
          setdatacontentId(response?.data?.data?.content_id || contentid);
        }
        setDetailsData(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      if (error.message === "Request failed with status code 422") {
        console.log(error.message);
      } else if (error?.response?.status === 422) {
        console.log(error?.response?.data?.error?.message);
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      setShowPreviewVideo(true);
    }
  }, [isLoggedIn]);


  const getPreviewVideosWithoutLogin = async () => {
    if (sessionID === undefined || sessionID === null) {
       sessionID = "";
  }
    const timeStamp = JSON.stringify(Math.floor(new Date().getTime() / 1000.0));
    const md5val =
    selectedCatalogID + selectedContentID + sessionID + timeStamp + getSecretKey;
    const md5Hash = crypto.MD5(md5val).toString();
    try {
      const response = await axios.post(GET_ALL_DETAILS, {
        auth_token,
        access_token,
        catalog_id: selectedCatalogID,
        category: "",
        content_id: selectedContentID,
        id: sessionID,
        region: regionsData?.country_code2,
        md5: md5Hash,
        ts: timeStamp,
      });

      if (response.status === 200) {
        if(videodetailsitems?.access_control?.login_required === true && response?.data?.data?.login_status === false){
          dispatch(setModalOpen())
        }else{
          setShowPreviewVideo(false);
          setLayer(false);
          setGetPreviewWithoutLogin(response.data.data);
          dispatch(setGetallDetails({getallDetails: response.data.data}))
          handleShowLayer();
        }
        // fetchDetailsPage(subcatcontent);
      }
    } catch (error) {
      setLayer(true);
      setShowPreviewVideo(true);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setLayer(false); 
  }, [videodetailsitems])


  const { loginDetails } = useSelector((state) => state?.signin_data || {});

  // useEffect(() => {
  // if(loginDetails?.email_id){
  //  window.location.reload()
  // }
  // }, [loginDetails])


  if (category === "live") {
    detailsData = detailssubData;
  }
  const renderLiveVideoPlayer = () => {
    let myseourl = "";
    // alert(serialSeourl);
    if (category === "shows") {
      myseourl = redirectSeourl;
    } else if (category === "serials") {
      myseourl = redirectSeourl;
    } else if (category === "events") {
      myseourl = redirectSeourl;
    }
    if (showPreviewVideo) {
      // console.log("sdjsjdjsd", videodetailsitems?.thumbnails?.high_16_9?.url)
      const url = videodetailsitems?.share_url || 'ETVWin';
      const extractedUrl = url.replace(/^https?:\/\//, ''); 
      return (
        <>
          <div className="image-containers">
            <div className="imgabove-layer">
              {videodetailsitems?.thumbnails?.high_16_9?.url ? (
                <img src={videodetailsitems?.thumbnails?.high_16_9?.url} alt="" />
              ) : (
                <SkeletonVideoThumbnail height={430} />
              )}

              <div className="showvideodetailsbelow">
                <div className="horizontal-divider-container">
                  <div className="horizontal-divider"></div>
                </div>
                <Container fluid className="pading-left-right-class">
                  <Row>
                    <Col>
                      <div className="flex-container videop">
                        <div className="combination">
                          <div className="imgabove-layer">
                            <div className="circle-icon">
                              {category === "shows" ||
                              category === "serials" ||
                              category === "events" ? (
                                <Link to={redirectSeourl}>
                                  <FaPlay />
                                </Link>
                              ) : (
                                <Link onClick={getPreviewVideosWithoutLogin}>
                                  <FaPlay />
                                </Link>
                              )}
                            </div>
                          </div>
                          <div className="text">Watch Now</div>
                        </div>

                        {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates} detailsData={detailsData} /> */}
                        <div className="divider"></div>
                        {detailsData?.media_type === "episode" && (
                          <div className="combination mb-3">
                            {detailsData.average_user_rating && (
                              <div className="section-header"></div>
                            )}
                          </div>
                        )}

                        <ShareFeature detailsData={detailsData} />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      (!getPreviewWithoutLogin?.login_status ||
        getPreviewWithoutLogin?.login_status) &&
      !getPreviewWithoutLogin?.is_subscribed &&
      (getPreviewWithoutLogin?.stream_info?.preview_available === true ||
        getPreviewWithoutLogin?.stream_info?.adaptive_url !== "")
    ) {
      return (
        <>
          <Row>
            <Col lg={12}>
              <CustomVideoPlayer
                videoSource={getPreviewWithoutLogin}
                videoDetails={detailsData}
              />
              <div className="flex-container videop">
                <Likefeature detailsData={detailsData} />
                <ShareFeature detailsData={detailsData} />
              </div>
            </Col>
            {/* <Col lg={4}>
              <Link>
                <img src={addBanner} alt="" className="w-100 add-banner" />
              </Link>
            </Col> */}
          </Row>
        </>
      );
    } else if (
      !getPreviewWithoutLogin?.login_status &&
      !getPreviewWithoutLogin?.is_subscribed &&
      !getPreviewWithoutLogin?.stream_info?.preview_available
    ) {
      const handleSubscribe =  () => {
        dispatch(setSubscribeSource({
          subscribeSource: true,
        }))
  
        navigate("/Subscription"); 
        }
      return (
        <>
          {layer && <div className="layer-image"></div>}

          {layer && (
            <div className="banner-login-conte">
              <h6 className="text-center">Subscribe to watch this content</h6>

              <Row>
                <Col
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "center" }}
                >
                  <Link
                    to=""
                    className="login-button"
                    onClick={() => dispatch(setModalOpen())}
                  >
                    Login
                  </Link>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "center" }}
                >
                  {/* <Link to="/subscription" className="login-button">
                    Subscribe
                  </Link> */}
                  <button onClick={handleSubscribe}  className="login-button">
                    Subscribe
                  </button>
                </Col>
              </Row>
            </div>
          )}

          <div className="image-containers">
            <div className="imgabove-layer">
              {/* <div className="imgabove-layer-in">
                {videodetailsitems?.thumbnails?.high_16_9?.url && (
                  <div className="circle-icon-two">
                    <Link to="" onClick={handleShowLayer}>
                      <FaPlay />
                    </Link>
                  </div>
                )}
              </div> */}

              {videodetailsitems?.thumbnails?.high_16_9?.url ? (
                <img src={videodetailsitems?.thumbnails?.high_16_9?.url} alt="" />
              ) : (
                <SkeletonVideoThumbnail height={430} />
              )}
            </div>
          </div>

          <div className="showvideodetailsbelow">
            <div className="horizontal-divider-container">
              <div className="horizontal-divider"></div>
            </div>
            <Container fluid className="pading-left-right-class">
              <Row>
                <Col>
                  <div className="flex-container videop">
                    <div className="combination">
                      <div className="imgabove-layer">
                        <div className="circle-icon">
                          {category === "shows" ||
                          category === "serials" ||
                          category === "events" ? (
                            <Link to={redirectSeourl}>
                              <FaPlay />
                            </Link>
                          ) : (
                            <Link onClick={getPreviewVideosWithoutLogin}>
                              <FaPlay />
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="text">Watch Now</div>
                    </div>

                    {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates} detailsData={detailsData} /> */}
                    <div className="divider"></div>
                    {detailsData?.media_type === "episode" && (
                      <div className="combination mb-3">
                        {detailsData.average_user_rating && (
                          <div className="section-header"></div>
                        )}
                      </div>
                    )}

                    {/* <WatchListFeature detailsData={detailsData} />
                      <Likefeature detailsData={detailsData} /> */}
                    <ShareFeature detailsData={detailsData} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      );
    } else if (
      getPreviewWithoutLogin?.login_status &&
      !getPreviewWithoutLogin?.is_subscribed &&
      !getPreviewWithoutLogin?.stream_info?.preview_available
    ) {
      const handleSubscribe =  () => {
        dispatch(setSubscribeSource({
          subscribeSource: true,
        }))
  
        navigate("/Subscription"); 
        }

      return (
        <>
          {layer && <div className="layer-image"></div>}

{layer && (
  <div className="banner-login-conte">
    <h6 className="text-center">Subscribe to watch this content</h6>

    <Row className="justify-content-center">
     
      <Col
        lg={6}
        md={6}
        sm={6}
        xs={6}
        style={{ textAlign: "center" }}
      >
        {/* <Link to="/subscription" className="login-button">
          Subscribe
        </Link> */}
        <button onClick={handleSubscribe}  className="login-button">
                    Subscribe
                  </button>
      </Col>
    </Row>
  </div>
)}

<div className="image-containers">
  <div className="imgabove-layer">
    {/* <div className="imgabove-layer-in">
      {videodetailsitems?.thumbnails?.high_16_9?.url && (
        <div className="circle-icon-two">
          <Link to="" onClick={handleShowLayer}>
            <FaPlay />
          </Link>
        </div>
      )}
    </div> */}

    {videodetailsitems?.thumbnails?.high_16_9?.url ? (
      <img src={videodetailsitems?.thumbnails?.high_16_9?.url} alt="" />
    ) : (
      <SkeletonVideoThumbnail height={430} />
    )}
  </div>
</div>

<div className="showvideodetailsbelow">
  <div className="horizontal-divider-container">
    <div className="horizontal-divider"></div>
  </div>
  <Container fluid className="pading-left-right-class">
    <Row>
      <Col>
        <div className="flex-container videop">
          <div className="combination">
            <div className="imgabove-layer">
              <div className="circle-icon">
                {category === "shows" ||
                category === "serials" ||
                category === "events" ? (
                  <Link to={redirectSeourl}>
                    <FaPlay />
                  </Link>
                ) : (
                  <Link onClick={getPreviewVideosWithoutLogin}>
                    <FaPlay />
                  </Link>
                )}
              </div>
            </div>
            <div className="text">Watch Now</div>
          </div>

          {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates} detailsData={detailsData} /> */}
          <div className="divider"></div>
          {detailsData?.media_type === "episode" && (
            <div className="combination mb-3">
              {detailsData.average_user_rating && (
                <div className="section-header"></div>
              )}
            </div>
          )}

          {/* <WatchListFeature detailsData={detailsData} />
            <Likefeature detailsData={detailsData} /> */}
          <ShareFeature detailsData={detailsData} />
        </div>
      </Col>
    </Row>
  </Container>
</div>
        </>
      );
    } else if (
      getPreviewWithoutLogin?.login_status &&
      getPreviewWithoutLogin?.is_subscribed 
    ) {
      return (
        <>
          <Row>
            <Col lg={12} md={12}>
            {getPreviewWithoutLogin?.stream_info
                      ?.is_stream_limit_exceed === true ? (
                      <>
                        <div
                          className="StreamLimits"
                          style={{
                            backgroundImage: `url(${videodetailsitems?.thumbnails?.high_16_9?.url})`,
                          }}
                        >
                          <div className="info">
                            <h6 className="text-center">
                              Maximum Simultaneous streams reached
                            </h6>
                            <p>
                              Please stop playing on other devices & click retry
                            </p>
                            <p>or</p>
                            <p>
                              logout of all / any devices to continue watching
                            </p>

                            <Row>
                              <Col
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6}
                                style={{ textAlign: "center" }}
                              >
                               <Link onClick={() => {
  if (getPreviewWithoutLogin?.stream_info?.is_stream_limit_exceed === true) {
    getPreviewVideosWithoutLogin();
  } else {
    window.location.reload();
  }
}} className="login-button w-100">
  Retry
</Link>
                              </Col>
                              <Col
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6}
                                style={{ textAlign: "center" }}
                              >
                                <Link
                                  to=""
                                  className="login-button w-100"
                                  onClick={() =>
                                    dispatch(
                                      setShowLogoutAllModal({
                                        showLogoutAllModal: true,
                                      })
                                    )
                                  }
                                >
                                  Signout all devices
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </>
                    ) : (
                      <CustomVideoPlayer videoSource={getPreviewWithoutLogin} />
                    )}
              <div className="flex-container videop">
                {/* <WatchNowFeature myseourl={myseourl} getPreviewVideosWithoutLogin={getPreviewVideosWithoutLogin} episodeDates={episodeDates} detailsData={detailsData} />
                      <div className="divider"></div> */}
                {/* <WatchListFeature detailsData={detailsData} /> */}
                <Likefeature detailsData={detailsData} />
                <ShareFeature detailsData={detailsData} />
              </div>
            </Col>
          </Row>
        </>
      );
    }
  };

  return (
    <>
      <div className={`main-video ${getPreviewWithoutLogin?.stream_info?.is_stream_limit_exceed === true ? 'streamlimitexceed' : ""}`}>
        <Row>
          <Col lg={8}>
            <div className="mainbanner">
              <div className="videomainid">{renderLiveVideoPlayer()}</div>
            </div>
          </Col>
          <Col lg={4}>
          <GPTAd />
            {/* <Link>
              <img src={addBanner} alt="" className="w-100 add-banner" />
            </Link> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LiveVideoPlayer;
