import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BASE_URL,
  OTP_VERIFICATION_URL,
  access_token,
  auth_token,
} from "./services/config";
import { toast } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { setShowSuccessMessage } from "./Redux/Slice/showprofileSlice";
import { setUpdateMobileModalShow } from "./Redux/Slice/updateMobileSlice";

export default function Verifyotp(props) {
  const {
    emailRegisterData,
    updatedMobile,
    showVerifyOTPPopup,
    setShowVerifyOTPPopup,
    sessionID
  } = props;

  const [currentIndex, setCurrentIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const { loginDetails } = useSelector((state) => state?.signin_data || {});
  const getloginDetails = localStorage.getItem("loginDetailsdata")
  const getuserid = JSON.parse(getloginDetails)
  console.log("getuserid",getuserid.email_id  )
  const sessionidfromlocalStorage = localStorage.getItem("session_id")
  const emailid = localStorage.getItem("email")
  const sessionId = sessionID || loginDetails?.session || sessionidfromlocalStorage

  const handleVerifyOtpPopupClose = () => {
    setShowVerifyOTPPopup(false);
  };

  const dispatch = useDispatch();
  const regionsData = useSelector((state) => state.region.regionsData);
  const [loginOTPs, setLoginOTPs] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const [error, setError] = useState("");
  const [timer, setTimer] = useState(20);
  const [timerCompleted, setTimerCompleted] = useState(false);
  const [loginSuccessmessage, setLoginSuccessmessage] = useState("");
  const [resdata, setResdata] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setTimerCompleted(true);
    }
  }, [timer]);

  const handleInputChange = (index, value) => {
    const newOtp = [...loginOTPs];
    newOtp[index] = value;
    setLoginOTPs(newOtp);

    if (value !== "" && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && loginOTPs[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();

    const enteredOTP = loginOTPs.join("");

    if (!enteredOTP) {
      setError("Please enter OTP");
      setCurrentIndex(0); 
      inputRefs[0]?.current?.focus();
      return;
    }
    setLoading(true)
    try {
      const response = await axios.post(
        OTP_VERIFICATION_URL,
        {
          auth_token: auth_token,
          access_token: access_token,
          user: {
            region: regionsData?.country_code2,
            action: "update_mobile",
            key: enteredOTP,
            mobile_number: `${updatedMobile}`,
            user_id: emailRegisterData?.email_id || emailid || getuserid.email_id,
            type: "msisdn",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        setShowVerifyOTPPopup(false);
        dispatch(setShowSuccessMessage({ showSuccessMessage: true }));
        localStorage.setItem("loginDetailsdata", JSON.stringify(response?.data?.data))
        dispatch(setUpdateMobileModalShow({updateMobileNumber: false}))
        setResdata(response?.data?.data);

        setLoginOTPs(["", "", "", "", "", ""]);
        toast.success("Now both Email/mobile number can be used for Signin!");
      }
    } catch (err) {
      console.error("Server login responded with an error:", err);
      if (err?.response?.status === 422) {
        localStorage.setItem("login_status", false);
        if (err?.response?.data?.error?.message === "Enter valid OTP") {
          toast.error(err?.response?.data?.error?.message);
          setError(err?.response?.data?.error?.message);
          setCurrentIndex(0); 
          inputRefs[0]?.current?.focus(); 
        }
      }
    }finally {
      setLoading(false); 
    }

    setLoginOTPs(["", "", "", "", "", ""]);
  };

  const MOBILE_NUMBER_UPDATE_URL = `${BASE_URL}users/${sessionId}/generate_mobile_otp
  `;

  const handleResendLoginOTP = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        MOBILE_NUMBER_UPDATE_URL,
        {
          auth_token: auth_token,
          access_token: access_token,
          profile: {
            user_id: `${updatedMobile}`,
            type: "msisdn",
            region: regionsData?.country_code2,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        console.log("responsess", response)
        toast.success(response?.data?.message || response?.data?.data?.message || response?.data?.data?.messgae || response?.data?.success?.message);
        setLoginSuccessmessage(response?.data?.message || response?.data?.data?.message || response?.data?.data?.messgae || response?.data?.success?.message);

        setLoginOTPs(["", "", "", "", "", ""]);
        setCurrentIndex(0); 
        inputRefs[0]?.current?.focus(); 

        setTimer(35);
        setTimerCompleted(false);
      } else {
      }
    } catch (error) {
      if (error.response) {
        if (error?.response?.status === 422) {
          toast.error(error?.response?.data?.error?.message);
          if (error?.response?.data?.error?.message === "Enter valid OTP") {
            setError(error?.response?.data?.error?.message);
            setCurrentIndex(0); 
            inputRefs[0]?.current?.focus(); 
          }
        }

        console.error(
          "Server responded with an error:",
          error?.response
          // error?.response?.data?.error?.message
        );
      }
    }
  };

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  return (
    <>
      {showVerifyOTPPopup && (
        <Modal
          show={showVerifyOTPPopup}
          // onHide={handleVerifyOtpPopupClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
            <div className="card-deck row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="login-left-styles">
                  <h6>OTP Verification</h6>

                  {loginSuccessmessage ? <p> {loginSuccessmessage} </p> : ""}
                  {error ? (
                    <p>{error} </p>
                  ) : (
                    <>
                      <p>Enjoy ETV Win with More Benefits and Features </p>
                    </>
                  )}

                  <div className="pad-t-b"></div>
                </div>
                <div className="form-style register">
                  <div className="form-style">
                    <form onSubmit={handleVerifyOTP}>
                      <ul className="otp-input">
                        {loginOTPs.map((digit, index) => (
                          <li key={index}>
                            <input
                              key={index}
                              ref={inputRefs[index]}
                              type="text"
                              value={digit}
                              // onChange={(e) =>
                              //   handleInputChange(index, e.target.value)
                              // }
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  handleInputChange(index, value);
                                }
                              }}
                              onKeyDown={(e) => handleKeyDown(index, e)}
                              maxLength="1"
                              autoComplete="off"
                              onFocus={() => setCurrentIndex(index)}
                              className={
                                currentIndex === index ? "red-border" : ""
                              }
                            />
                          </li>
                        ))}
                      </ul>
                      <button className="next-button-color">{loading ? <Spinner /> : 'Activate'}</button>
                    </form>
                  </div>
                  <div className="time-div">
                    {timerCompleted ? (
                      <Link onClick={handleResendLoginOTP}>Resend OTP</Link>
                    ) : (
                      <p>
                        Retry in &nbsp;{" "}
                        <span>00:{timer < 10 ? `0${timer}` : timer}</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
